// Vertex shader based on vtkPolyDataVS.glsl generated by vtk for vertex shading.

export const RegionHighlightVP =
  `//VTK::System::Dec
  
  attribute vec4 vertexMC;
  
  // frag position in VC
  out vec4 vertexVCVSOutput;
  
  // optional normal declaration
  attribute vec3 normalMC;
  uniform mat3 normalMatrix;
  out vec3 normalVCVSOutput;
  
  // camera and actor matrix values
  uniform mat4 MCPCMatrix;
  uniform mat4 MCVCMatrix;
  
  // Mapper scale
  uniform vec3 scale;

  void main()
  {
    normalVCVSOutput = normalMatrix * normalMC;

    // Offset vertex along normal
    float s = 1.5;
    vec4 v = (vertexMC + vec4(normalMC * scale * s, 0.0));

    gl_Position = MCPCMatrix * v;
  }`;