// Fragment shader based on vtkPolyDataFS.glsl generated by vtk for vertex shading.

export const RegionHighlightFP =
  `//VTK::System::Dec
  
  uniform int PrimitiveIDOffset;
  
  // optional color passed in from the vertex shader, vertexColor
  uniform vec3 diffuseColorUniform;
  
  // optional surface normal declaration
  in vec3 normalVCVSOutput;
  
  // the output of this shader
  layout(location = 0) out vec4 fragOutput0;
  
  // handle coincident offsets
  uniform float cfactor;
  uniform float coffset;
  
  void main()
  {  
    // Place any calls that require uniform flow (e.g. dFdx) here.
    float cscale = length(vec2(dFdx(gl_FragCoord.z),dFdy(gl_FragCoord.z)));
  
    // Set gl_FragDepth here (gl_FragCoord.z by default)
    gl_FragDepth = gl_FragCoord.z + cfactor*cscale + 0.000016*coffset;
  
    // Generate the normal if we are not passed in one
    vec3 normalVCVSOutput = normalize(normalVCVSOutput);
    if (gl_FrontFacing == false) { normalVCVSOutput = -normalVCVSOutput; }
  
    float df = max(0.0, normalVCVSOutput.z);

    // Apply edge highlighting
    fragOutput0 = vec4(diffuseColorUniform, 0.5);
  
    if (fragOutput0.a <= 0.0)
      {
      discard;
      }
  }`;